<template>
  <section id="risk-matrix">
    <pibot-section-heading>
      <slot>Risk Matrix Considerations</slot>
    </pibot-section-heading>

    <pibot-asset-risk-matrix-revisions v-if="features.revisions" />
    <pibot-asset-risk-matrix-table class="py-5" />
  </section>
</template>

<script>
import SectionHeading from '@/components/typography/headings/SectionHeading'
import config from '../../../config'

export default {
  name: 'pibot-asset-risk-matrix',
  components: {
    'pibot-section-heading': SectionHeading,
    'pibot-asset-risk-matrix-table': () => import('./MatrixTable'),
    'pibot-asset-risk-matrix-revisions': () => import('./Revisions')
  },
  data () {
    return {
      features: config.features
    }
  }
}
</script>
